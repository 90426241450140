// cityOptions.js

const cityOptions = [
  { label: "Minneapolis, MN", value: "Minneapolis, MN" },
  { label: "St. Paul, MN", value: "St. Paul, MN" },
  { label: "Rochester, MN", value: "Rochester, MN" },
  { label: "Duluth, MN", value: "Duluth, MN" },
  { label: "Bloomington, MN", value: "Bloomington, MN" },
  { label: "Brooklyn Park, MN", value: "Brooklyn Park, MN" },
  { label: "Plymouth, MN", value: "Plymouth, MN" },
  { label: "Maple Grove, MN", value: "Maple Grove, MN" },
  { label: "Woodbury, MN", value: "Woodbury, MN" },
  { label: "St. Cloud, MN", value: "St. Cloud, MN" },
  { label: "Eagan, MN", value: "Eagan, MN" },
  { label: "Eden Prairie, MN", value: "Eden Prairie, MN" },
  { label: "Minnetonka, MN", value: "Minnetonka, MN" },
  { label: "Burnsville, MN", value: "Burnsville, MN" },
  { label: "Lakeville, MN", value: "Lakeville, MN" },
  { label: "Apple Valley, MN", value: "Apple Valley, MN" },
  { label: "Edina, MN", value: "Edina, MN" },
  { label: "St. Louis Park, MN", value: "St. Louis Park, MN" },
  { label: "Mankato, MN", value: "Mankato, MN" },
  { label: "Maplewood, MN", value: "Maplewood, MN" },
  { label: "Moorhead, MN", value: "Moorhead, MN" },
  { label: "Shakopee, MN", value: "Shakopee, MN" },
  { label: "Cottage Grove, MN", value: "Cottage Grove, MN" },
  { label: "Inver Grove Heights, MN", value: "Inver Grove Heights, MN" },
  { label: "Andover, MN", value: "Andover, MN" },
  { label: "Brooklyn Center, MN", value: "Brooklyn Center, MN" },
  { label: "Savage, MN", value: "Savage, MN" },
  { label: "White Bear Lake, MN", value: "White Bear Lake, MN" },
  { label: "Richfield, MN", value: "Richfield, MN" },
  { label: "Chaska, MN", value: "Chaska, MN" },
  { label: "Chanhassen, MN", value: "Chanhassen, MN" },
  { label: "Austin, MN", value: "Austin, MN" },
  { label: "Elk River, MN", value: "Elk River, MN" },
  { label: "Ramsey, MN", value: "Ramsey, MN" },
  { label: "Faribault, MN", value: "Faribault, MN" },
  { label: "Prior Lake, MN", value: "Prior Lake, MN" },
  { label: "Hastings, MN", value: "Hastings, MN" },
  { label: "Albert Lea, MN", value: "Albert Lea, MN" },
  { label: "Hopkins, MN", value: "Hopkins, MN" },
  { label: "New Brighton, MN", value: "New Brighton, MN" },
  { label: "Northfield, MN", value: "Northfield, MN" },
  { label: "Stillwater, MN", value: "Stillwater, MN" },
  { label: "Willmar, MN", value: "Willmar, MN" },
  { label: "Marshall, MN", value: "Marshall, MN" },
  { label: "Bemidji, MN", value: "Bemidji, MN" },
  { label: "Hibbing, MN", value: "Hibbing, MN" },
];


export default cityOptions;